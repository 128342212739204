<template>
  <div id="soundboard">
    <div class="buttons">
      <button v-for="(playlist, index) in playlists" :key="index" @click="playPlaylist(index)">
        <div class="cover">
          <img :src="'/covers/' + playlist.image" />
          <div class="btn-play">
            <i class="fa fa-play"></i>
          </div>
        </div>
        <!-- <i class="ra" :class="playlist.icon"></i> -->
        <p>{{ playlist.name }}</p>
      </button>
    </div>
    <div v-if="currentTrack" class="player">
      <div class="player-info">
        
        <div class="track-info">
          <h2>{{ currentTrack.name }}</h2>
        </div>
      </div>
      <div class="controls">
        <div class="player-control">
          <button @click="toggleLoop">
            <i :class="isLooping ? 'fa fa-redo' : 'fa fa-redo-alt'"></i>
          </button>
          <button @click="togglePlay" class="play-pause-btn">
            <i :class="isPlaying ? 'fa fa-pause' : 'fa fa-play'"></i>
          </button>
          <button @click="skipTrack">
            <i class="fa fa-forward-step"></i>
          </button>
        </div>
        <div class="progression" style="display: none;">
          <span>{{ formatTime(currentTime) }}</span>
          <input type="range" min="0" :max="duration" v-model="progress" @input="seek" class="progress-bar" />
          <span>{{ formatTime(duration) }}</span>
        </div>
      </div>
      <audio ref="audioPlayer" style="display: none;" :src="currentTrack.url" @canplaythrough="onCanPlayThrough" @timeupdate="updateProgress" @ended="handleEnded" :loop="isLooping"></audio>
      <audio ref="audioPreloader" style="display: none;" :src="nextTrack.url" preload="auto"></audio>
      <div class="volume-control">
        <i class="fa fa-volume-up"></i>
        <input type="range" min="0" max="1" step="0.01" v-model="volume" @input="updateVolume" />
      </div>

      <div class="custom_progression">
        <div class="current_progression" :style="'width:' + (100*progress/duration) + '%'"></div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted} from 'vue';

// Funzione per generare le tracce dinamicamente
const generateTracks = (prefix, count) => {
  return Array.from({ length: count }, (_, i) => ({
    url: `/sounds/${prefix}/${prefix}-${i}.mp3`,
    name: `${prefix.charAt(0).toUpperCase() + prefix.slice(1)} ${i + 1}`
  }));
};

// Lista delle playlist
const playlists = ref([
  { name: 'Taverna', icon: 'ra-beer', image: 'tavern.jpg', tracks: generateTracks('tavern', 50) },
  { name: 'Battle', icon: ' ra-crossed-axes', image: 'battle.jpg', tracks: generateTracks('battle', 50) },
  { name: 'Adventure', icon: ' ra-crossed-axes', image: 'travel.jpg', tracks: generateTracks('travel', 50) },
  { name: 'Dark', icon: ' ra-crossed-axes', image: 'dark.jpg', tracks: generateTracks('dark', 50) },
  { name: 'Fairy', icon: ' ra-crossed-axes', image: 'fairy.jpg', tracks: generateTracks('fairy', 9) },
  { name: 'Askel', icon: ' ra-crossed-axes', image: '', tracks: generateTracks('askel', 4) },
  // { name: 'Pirate', tracks: generateTracks('dark', 0) },
  // { name: 'Adventure', tracks: generateTracks('dark', 0) }
]);

const currentPlaylistIndex = ref(null);
const currentTrackIndex = ref(null);
const currentTrack = ref(null);
const nextTrackIndex = ref(null);
const nextTrack = ref(null);
const audioPreloader = ref(null);
const audioPlayer = ref(null);
const isPlaying = ref(false);
const isLooping = ref(false);
const duration = ref(0);
const currentTime = ref(0);
const progress = ref(0);
const volume = ref(1);


const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};


// Avvia la riproduzione della playlist selezionata
const playPlaylist = (index) => {
  currentPlaylistIndex.value = index;
  currentTrackIndex.value = 0;

  playlists.value[currentPlaylistIndex.value].tracks = shuffleArray(playlists.value[currentPlaylistIndex.value].tracks);

  playTrack();
};

// Riproduce la traccia corrente
const playTrack = () => {
  const playlist = playlists.value[currentPlaylistIndex.value];
  currentTrack.value = playlist.tracks[currentTrackIndex.value];
  console.log(`Playing track: ${currentTrack.value.name}`);
  if (audioPlayer.value) {
    audioPlayer.value.pause();
    audioPlayer.value.currentTime = 0;
    audioPlayer.value.src = currentTrack.value.url;  // Imposta la sorgente solo per la traccia corrente
    progress.value = 0;
    audioPlayer.value.load();  // Carica solo la traccia corrente
  }

  // Precarica la traccia successiva
  nextTrackIndex.value = (currentTrackIndex.value + 1) % playlist.tracks.length;
  nextTrack.value = playlist.tracks[nextTrackIndex.value];
  if (audioPreloader.value) {
    console.log(nextTrack.value.url)
    audioPreloader.value.src = nextTrack.value.url;
    audioPreloader.value.load();
  }
};


const skipTrack = () => {
  handleEnded()
}

// Quando il nuovo brano è pronto per essere riprodotto
const onCanPlayThrough = () => {
  if (audioPlayer.value) {
    audioPlayer.value.play().catch(error => {
      console.error("Playback error: ", error);
    });
    isPlaying.value = true;
  }
};

// Alterna tra play e pause
const togglePlay = () => {
  if (isPlaying.value) {
    audioPlayer.value.pause();
  } else {
    audioPlayer.value.play().catch(error => {
      console.error("Playback error: ", error);
    });
  }
  isPlaying.value = !isPlaying.value;
};

// Alterna tra loop attivo e disattivo
const toggleLoop = () => {
  isLooping.value = !isLooping.value;
  if (audioPlayer.value) {
    audioPlayer.value.loop = isLooping.value;
  }
};

// Aggiorna la progressione e il tempo corrente
const updateProgress = () => {
  progress.value = audioPlayer.value.currentTime;
  currentTime.value = audioPlayer.value.currentTime;
  duration.value = audioPlayer.value.duration;
};

// Cambia la posizione di riproduzione
const seek = () => {
  audioPlayer.value.currentTime = progress.value;
};

// Aggiorna il volume
const updateVolume = () => {
  audioPlayer.value.volume = volume.value;
};

// Formatta il tempo in minuti e secondi
const formatTime = (time) => {
  const minutes = Math.floor(time / 60);
  const seconds = Math.floor(time % 60).toString().padStart(2, '0');
  return `${minutes}:${seconds}`;
};

// Gestisce la fine della riproduzione della traccia corrente
const handleEnded = () => {
  isPlaying.value = false;
  progress.value = 0;
  currentTime.value = 0;
  // Passa alla traccia successiva nella playlist
  if (currentTrackIndex.value < playlists.value[currentPlaylistIndex.value].tracks.length - 1) {
    currentTrackIndex.value += 1;
    playTrack();
  }
  else {
    currentTrackIndex.value = 0;
    playTrack();
  }
};

onMounted(() => {
  if (audioPlayer.value) {
    audioPlayer.value.addEventListener('loadedmetadata', () => {
      duration.value = audioPlayer.value.duration;
    });
  }
});
</script>

<style>
#app, body {
  background: #121212;
  padding: 0;
  margin: 0;
}
</style>
<style scoped lang="scss">
#soundboard {
  font-family: Arial, sans-serif;
  text-align: center;
  color: #fff;
  padding: 20px;
}

h1 {
  margin-bottom: 20px;
  color: #fff;
}

.buttons {
  display: grid;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  grid-template-columns: repeat(2, 1fr);

  @media (min-width: 576px) { grid-template-columns: repeat(2, 1fr); }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) { grid-template-columns: repeat(4, 1fr); }

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) { grid-template-columns: repeat(6, 1fr); }

  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) { grid-template-columns: repeat(8, 1fr); }

  button {
    border-radius: 6px;
    border: none;
    background: none;
    color: white;
    font-size: 24px;
    cursor: pointer;
    transition: background 0.3s;
    padding: 10px;
    &:disabled {
      background: #9E9E9E;
      cursor: not-allowed;
    }
    &:hover:not(:disabled) {
      background: #212121;
    }

    .cover {
      position: relative;
      img {
        border-radius: 6px;
        box-shadow: 0 8px 24px rgba(0, 0, 0, .5);
        display: block;
        max-width: 100%;
      }
      .btn-play {
        background: #7b2121;
        border-radius: 50px;
        display: flex;
        height: 50px;
        width: 50px;
        left: 50%;
        top: 50%;
        position: absolute;
        transform: translate(-50%, -50%);
        align-items: center;
        justify-content: center;
      }
    }
    p {
      font-size: 16px;
      margin: 10px 0 0;
      padding: 0;
    }
  }
}

.player {
  background: #090909;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  color: #fff;
  padding: 10px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;

  .player-info {
    display: flex;
    align-items: center;
    width: 30%;
    padding: 10px;
    i {
      font-size: 24px;
      cursor: pointer;
      margin-right: 20px;
    }
    .track-info {
      display: flex;
      flex-direction: column;
      h2, p {
        margin: 0;
      }
      h2 {
        font-size: 16px;
        color: #fff;
      }
      p {
        font-size: 12px;
        color: #bbb;
      }
    }
  }

  .controls {
    display: flex;
    align-items: center;
    width: 40%;
    justify-content: space-between;
    flex-direction: column;
    .progression {
      flex: 1;
      display: flex;
      margin: 10px 10px 0;
      width: 100%;

      .progress-bar {
        width: 100%;
      }
    }
    
    span {
      margin: 0 10px;
    }
    .player-control {
      display: flex;
      align-content: center;
      align-items: center;
    }
    button {
      border: none;
      background: transparent;
      color: #fff;
      font-size: 24px;
      cursor: pointer;
      transition: color 0.3s;
      &:hover {
        color: #4CAF50;
      }
      i {
          display: block;
        height: 72px;
        width: 72px;
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
      }
      &.play-pause-btn {
        i {
          background: white;
          border-radius: 72px;
          color: #090909
        }
      }
    }
  }

  .volume-control {
    display: flex;
    align-items: center;
    width: 30%;
    justify-content: flex-end;
    i {
      margin-right: 10px;
    }
    input[type="range"] {
      width: 100px;
    }
  }

  .actions {
    display: flex;
    align-items: center;
    margin-top: 10px;
    button {
      border: none;
      background: transparent;
      color: #fff;
      font-size: 24px;
      cursor: pointer;
      margin: 0 10px;
      transition: color 0.3s;
      &:hover {
        color: #4CAF50;
      }
      i {
        margin-right: 5px;
      }
    }
  }

  .custom_progression {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    height: 3px;
    z-index: 1;
    .current_progression {
      height: 100%;
      background: white;
    }
  }
}
</style>
